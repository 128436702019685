import { useEffect } from 'react';
import '../App.css'
import $ from 'jquery';
function Home() {


  useEffect( async ()=>{
    function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
  }
  await timeout(3000); //for 1 sec delay

  $('#ytv')[0].click();
    // play.trigger('click');
    // play.filter('.sender').trigger( "click" );
    // alert('t')
  });
  return (
    <div className="App">
      {/* <!DOCTYPE html> */}
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="icon" type="image/png" href="assets/img/favicon.png" />
          <link href='https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css' rel='stylesheet' />
          <link rel="stylesheet" href="assets/css/styles.css" />
          <title>Document</title>
        </head>
        <body class="antialiased">

          <main>

            <div class="h-screen bg-blue-50 overflow-hidden relative">
              {/* <!-- Start Hero --> */}
              <div class="h-96 h-full container mx-auto px-5">
                <div class="hidden xl:block absolute top-1">
                  {/* <a id='title' href="#">
                    <h1 class="text-4xl sm:text-5xl xl:text-7xl font-semibold">18 cowboys</h1>
                  </a> */}
                </div>
                <div class="xl:h-96 grid xl:mt-60 content-center p-10">
                  <div class="col-span-1 space-y-5 md:space-y-8">

                    <div class="aspect-w-16 aspect-h-9">
                      <iframe id="ytv" src="https://www.youtube-nocookie.com/embed/MADvxFXWvwE?autoplay=1&cc_load_policy=1" title="YouTube video player" frameborder="0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </body>
      </html>
    </div>
  );
}

export default Home;